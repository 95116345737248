html, 
body {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.mat-card {
  overflow: hidden;
}

img:not(.mat-card-image) {
  max-width: 100%;
}
a, a:focus, a:hover {
  text-decoration: none;
}
a {
  color: inherit;
}
p {
  margin: 0 0 16px;
}

.text-center {
  text-align: center;
}

.text-muted {
  opacity: 0.64 !important;
}

.text-gray {
  opacity: 0.7 !important;
}

.text-small {
  font-size: .813rem;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -868px 0
  }
  100%{
      background-position: 868px 0
  }
}

.text-placeholder {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(90deg, rgba(200, 200, 200, 0.335) 25%, rgba(31, 31, 31, 0.178) 50%, rgba(200, 200, 200, 0.335) 75%);
  background-size: 800px 104px;
}

.hidden {
  visibility: hidden;
}

mat-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  border-radius: 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;

  app-enjoy-icon {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
    margin-right: 4px !important;
  }
}

small {
  font-size: small;
}

mat-card-title {
  h3 {
    font-weight: 500 !important;
    margin-bottom: .8rem !important;
    margin-left: .5rem !important;
  }
}