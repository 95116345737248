/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '~@angular/material' as mat;

@include mat.core();

body {
  --theme-background: #f6f7f9;
  --theme-text: #000;
  --theme-card: #FFF;
}

body {
  --theme-primary-50: #e5e5e9;
  --theme-primary-100: #bfbfc7;
  --theme-primary-200: #9494a2;
  --theme-primary-300: #69697c;
  --theme-primary-400: #484960;
  --theme-primary-500: #282944;
  --theme-primary-600: #24243e;
  --theme-primary-700: #1e1f35;
  --theme-primary-800: #18192d;
  --theme-primary-900: #0f0f1f;
  --theme-primary-A100: #6161ff;
  --theme-primary-A200: #2e2eff;
  --theme-primary-A400: #0000fa;
  --theme-primary-A700: #0000e0; 
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff; 
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #ffffff;
  --theme-primary-contrast-A200: #ffffff;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;
}

$mat-primary: (
  50 : var(--theme-primary-50),
  100 : var(--theme-primary-100),
  200 : var(--theme-primary-200),
  300 : var(--theme-primary-300),
  400 : var(--theme-primary-400),
  500 : var(--theme-primary-500),
  600 : var(--theme-primary-600),
  700 : var(--theme-primary-700),
  800 : var(--theme-primary-800),
  900 : var(--theme-primary-900),
  A100 : var(--theme-primary-A100),
  A200 : var(--theme-primary-A200),
  A400 : var(--theme-primary-A400),
  A700 : var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  )
);

body {
  --ios-install-bg: #5a5858;
  --popup-background-color: #e5e6e8;
  --theme-accent-50: #fcf4e5;
  --theme-accent-100: #f8e3be;
  --theme-accent-200: #f4d093;
  --theme-accent-300: #efbd67;
  --theme-accent-400: #ebaf47;
  --theme-accent-500: #e8a126;
  --theme-accent-600: #e59922;
  --theme-accent-700: #e28f1c;
  --theme-accent-800: #de8517;
  --theme-accent-900: #d8740d;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffe6d1;
  --theme-accent-A400: #ffcb9e;
  --theme-accent-A700: #ffbe85; 
  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000; 
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;
  --theme-warn-500: #d32f2f;
  overscroll-behavior-y: contain;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

$mat-accent: (
  50 : var(--theme-accent-50),
  100 : var(--theme-accent-100),
  200 : var(--theme-accent-200),
  300 : var(--theme-accent-300),
  400 : var(--theme-accent-400),
  500 : var(--theme-accent-500),
  600 : var(--theme-accent-600),
  700 : var(--theme-accent-700),
  800 : var(--theme-accent-800),
  900 : var(--theme-accent-900),
  A100 : var(--theme-accent-A100),
  A200 : var(--theme-accent-A200),
  A400 : var(--theme-accent-A400),
  A700 : var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700),
  )
);

$mat-warn: mat.$red-palette;

$theme-primary: mat.define-palette($mat-primary);
$theme-accent: mat.define-palette($mat-accent);
$theme-warn: mat.define-palette($mat-warn);

$theme-dark-primary: mat.define-palette($mat-primary, 500, 200, 800);
$theme-dark-accent: mat.define-palette($mat-accent, 400, 100, 700);
$theme-dark-warn: mat.define-palette($mat-warn, 300, 100, 600);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$dark-Theme: mat.define-dark-theme($theme-dark-primary, $theme-dark-accent, $theme-dark-warn);

// Theme Init
@include mat.all-component-themes($theme);

// .dark-theme {
//   @include mat.all-component-themes($dark-Theme);
// }

body {
  background: var(--theme-background);
  color: var(--theme-color);
  font-family: custom, Roboto, "Helvetica Neue", sans-serif !important;
}

* {
  font-family: custom, Roboto, "Helvetica Neue", sans-serif !important;
}

.mat-card {
  color: var(--theme-color);
  background: var(--theme-card);
  mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

.text-primary {
  color: map-get($mat-primary, 500);
}

.text-accent {
  color: map-get($mat-accent, 500);
}

.text-warn {
  color: map-get($mat-warn, 500);
}

.primary {
  background: map-get($mat-primary, 500);
  color: map-get(map-get($mat-primary, contrast), 500);
}

.accent {
  background: map-get($mat-accent, 500);
  color: map-get(map-get($mat-accent, contrast), 500);
}

.warn {
  background: map-get($mat-warn, 500);
  color: map-get(map-get($mat-warn, contrast), 500);
}
