.overflow-hidden {
  overflow: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
